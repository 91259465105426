import { Module } from "vuex";

import {
  CartMembership,
  CartProduct,
  CartService,
  CartServicePackage,
  CartVoucher,
  Client,
  Discount,
} from "@/types";
import { api } from "@/util/axios";

type cartState = {
  cart: {
    products: CartProduct[];
    services: CartService[];
    memberships: CartMembership[];
    vouchers: CartVoucher[];
    servicePackages: CartServicePackage[];
  };
  client: Client | undefined;
  discounts: Discount[];
  appliedDiscounts: Discount[];
};

type CartItem =
  | CartProduct
  | CartService
  | CartServicePackage
  | CartMembership
  | CartVoucher;
type CartItemType =
  | "product"
  | "service"
  | "service-package"
  | "membership"
  | "voucher";

const getItemDiscount = (
  discounts: Discount[],
  item: CartItem,
  itemType: CartItemType
) => {
  let discountedValue = 0;
  for (let i = 0; i < discounts.length; i++) {
    const discount = discounts[i];

    if (itemType === "product") {
      const _item = item as CartProduct;
      if (
        !discount.allProducts &&
        !discount.productsApplied
          .map((item: any) => item._id)
          .includes(_item.product._id)
      ) {
        continue;
      }
    } else if (itemType === "service") {
      const _item = item as CartService;
      if (
        !discount.allServices &&
        !discount.servicesApplied
          .map((item: any) => item._id)
          .includes(_item.service._id)
      ) {
        continue;
      }
    } else if (itemType === "service-package") {
      const _item = item as CartServicePackage;
      if (
        !discount.allPackages &&
        !discount.servicePackagesApplied
          .map((item: any) => item._id)
          .includes(_item.servicePackage)
      ) {
        continue;
      }
    } else if (itemType === "membership") {
      const _item = item as CartMembership;
      if (
        !discount.allMemberships &&
        !discount.membershipsApplied
          .map((item: any) => item._id)
          .includes(_item.id)
      ) {
        continue;
      }
    }

    if (discount.valueType === "percentage") {
      discountedValue += (item.unitPrice * discount.value) / 100;
    } else {
      discountedValue += Math.min(item.unitPrice, discount.value);
    }
  }

  return Math.floor(discountedValue);
};

const cart: Module<cartState, unknown> = {
  namespaced: true,
  state: () => ({
    cart: {
      products: [],
      services: [],
      memberships: [],
      vouchers: [],
      servicePackages: [],
    },
    client: undefined,
    discounts: [],
    appliedDiscounts: [],
  }),
  getters: {
    products: (state) =>
      state.cart.products.map((item) => ({
        ...item,
        discount: getItemDiscount(state.appliedDiscounts, item, "product"),
      })),
    deposit: (state) => {
      let deposit = 0;

      state.cart.services.forEach((item) => {
        deposit += item.deposit || 0;
      });
      return deposit;
    },
    quantity: (state) => {
      let qty = 0;
      state.cart.products.forEach((item) => {
        qty += item.quantity;
      });

      state.cart.memberships.forEach((item) => {
        qty += item.quantity;
      });

      state.cart.services.forEach((item) => {
        qty += item.quantity;
      });

      state.cart.vouchers.forEach((item) => {
        qty += item.quantity;
      });

      state.cart.servicePackages.forEach((item) => {
        qty += item.quantity;
      });
      return qty;
    },
    services: (state) =>
      state.cart.services.map((item) => ({
        ...item,
        discount: getItemDiscount(state.appliedDiscounts, item, "service"),
      })),
    memberships: (state) =>
      state.cart.memberships.map((item) => ({
        ...item,
        discount: getItemDiscount(state.appliedDiscounts, item, "membership"),
      })),
    vouchers: (state) => state.cart.vouchers,
    client: (state) => state.client,
    servicePackages: (state) =>
      state.cart.servicePackages.map((item) => ({
        ...item,
        discount: getItemDiscount(
          state.appliedDiscounts,
          item,
          "service-package"
        ),
      })),
    getDiscount: (state) => {
      return [
        ...state.cart.products.map(
          (item) =>
            getItemDiscount(state.appliedDiscounts, item, "product") *
            item.quantity
        ),
        ...state.cart.services.map(
          (item) =>
            getItemDiscount(state.appliedDiscounts, item, "service") *
            item.quantity
        ),
        ...state.cart.servicePackages.map(
          (item) =>
            getItemDiscount(state.appliedDiscounts, item, "service-package") *
            item.quantity
        ),
        ...state.cart.memberships.map(
          (item) =>
            getItemDiscount(state.appliedDiscounts, item, "membership") *
            item.quantity
        ),
      ].reduce((acc: any, curr: any) => acc + curr, 0);
    },
    discounts: (state) =>
      state.discounts.map((dscnt) => ({ ...dscnt, selected: true })),
    total: (state) => {
      let total = 0;
      state.cart.products.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });

      state.cart.memberships.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });

      state.cart.services.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });

      state.cart.vouchers.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });

      state.cart.servicePackages.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });
      return total;
    },
    totalWithDeposit: (state) => {
      let total = 0;
      state.cart.products.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });

      state.cart.memberships.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });

      state.cart.services.forEach((item) => {
        total += item.deposit || 0;
      });

      state.cart.vouchers.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });

      state.cart.servicePackages.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });
      return total;
    },
    appliedDiscounts: (state) => state.appliedDiscounts,
  },
  mutations: {
    SET_CLIENT: (state, _client: any) => {
      state.client = _client;
    },
    DELETE_CART: (state) => {
      state.cart.products = [];
      state.cart.services = [];
      state.cart.memberships = [];
      state.client = undefined;
    },
    SET_DISCOUNTS: (state, discounts) => {
      state.discounts = discounts;
    },
    SET_APPLIED_DISCOUNTS: (state, discounts) => {
      state.appliedDiscounts = discounts;
    },
    ADD_ITEM: (state, data: { item: CartItem; itemType: CartItemType }) => {
      const { itemType, item } = data;
      if (itemType === "product") {
        const _item = item as CartProduct;
        const idx = state.cart.products.findIndex(
          (item) => item.sku === _item.sku
        );
        if (idx > -1) {
          state.cart.products[idx].quantity += 1;
        } else {
          state.cart.products.push(_item);
        }
      } else if (itemType === "service") {
        const _item = item as CartService;
        const idx = state.cart.services.findIndex(
          (item) => item.service === _item.service
        );
        if (idx > -1) {
          state.cart.services[idx].quantity += 1;
        } else {
          state.cart.services.push(_item);
        }
      } else if (itemType === "service-package") {
        const _item = item as CartServicePackage;
        const idx = state.cart.servicePackages.findIndex(
          (item) => item.servicePackage === _item.servicePackage
        );
        if (idx > -1) {
          state.cart.servicePackages[idx].quantity += 1;
        } else {
          state.cart.servicePackages.push(_item);
        }
      } else if (itemType === "membership") {
        const _item = item as CartMembership;
        const idx = state.cart.memberships.findIndex(
          (item) => item.id === _item.id
        );
        if (idx > -1) {
          state.cart.memberships[idx].quantity += 1;
        } else {
          state.cart.memberships.push(_item);
        }
      } else if (itemType === "voucher") {
        const _item = item as CartVoucher;
        const idx = state.cart.vouchers.findIndex(
          (item) => item.id === _item.id
        );
        if (idx > -1) {
          state.cart.vouchers[idx].quantity += 1;
        } else {
          state.cart.vouchers.push(_item);
        }
      }
    },
    UPDATE_ITEM: (state, data: { item: CartItem; itemType: CartItemType }) => {
      const { itemType, item } = data;
      if (itemType === "product") {
        const _item = item as CartProduct;

        const idx = state.cart.products.findIndex(
          (item) => item.sku === _item.sku
        );

        if (idx > -1) {
          state.cart.products[idx].quantity = item.quantity;
          state.cart.products[idx].unitPrice = item.unitPrice;
          if (item.quantity === 0) {
            state.cart.products = state.cart.products.filter(
              (item) => item.sku !== _item.sku
            );
          }
        }
      } else if (itemType === "service") {
        const _item = item as CartService;

        const idx = state.cart.services.findIndex(
          (item) => item.service === _item.service
        );

        if (idx > -1) {
          state.cart.services[idx].quantity = item.quantity;
          state.cart.services[idx].unitPrice = item.unitPrice;
          state.cart.services[idx].appointmentDate = _item.appointmentDate;
          state.cart.services[idx].appointmentTime = _item.appointmentTime;
          state.cart.services[idx].staff = _item.staff;
          if (item.quantity === 0) {
            state.cart.services = state.cart.services.filter(
              (item) => item.service !== _item.service
            );
          }
        }
      } else if (itemType === "service-package") {
        const _item = item as CartServicePackage;

        const idx = state.cart.servicePackages.findIndex(
          (item) => item.servicePackage === _item.servicePackage
        );

        if (idx > -1) {
          state.cart.servicePackages[idx].quantity = item.quantity;
          state.cart.servicePackages[idx].unitPrice = item.unitPrice;
          state.cart.servicePackages[idx].appointmentDate =
            _item.appointmentDate;
          state.cart.servicePackages[idx].appointmentTime =
            _item.appointmentTime;
          state.cart.servicePackages[idx].staff = _item.staff;
          if (item.quantity === 0) {
            state.cart.servicePackages = state.cart.servicePackages.filter(
              (item) => item.servicePackage !== _item.servicePackage
            );
          }
        }
      } else if (itemType === "membership") {
        const _item = item as CartMembership;

        const idx = state.cart.memberships.findIndex(
          (item) => item.id === _item.id
        );

        if (idx > -1) {
          state.cart.memberships[idx].quantity = item.quantity;
          state.cart.memberships[idx].unitPrice = item.unitPrice;
          if (item.quantity === 0) {
            state.cart.memberships = state.cart.memberships.filter(
              (item) => item.id !== _item.id
            );
          }
        }
      } else if (itemType === "voucher") {
        const _item = item as CartVoucher;

        const idx = state.cart.vouchers.findIndex(
          (item) => item.id === _item.id
        );

        if (idx > -1) {
          state.cart.vouchers[idx].quantity = item.quantity;
          state.cart.vouchers[idx].unitPrice = item.unitPrice;

          if (item.quantity === 0) {
            state.cart.vouchers = state.cart.vouchers.filter(
              (item) => item.id !== _item.id
            );
          }
        }
      }
    },
  },
  actions: {
    addItemToCart(context, data: { item: CartItem; itemType: CartItemType }) {
      context.commit("ADD_ITEM", data);
    },
    addItemListToCart(
      context,
      data: { items: CartItem[]; itemType: CartItemType }
    ) {
      const { items, itemType } = data;
      items.forEach((item) => {
        context.commit("ADD_ITEM", { item, itemType });
      });
    },
    updateCartItem(context, data: { item: CartItem; itemType: CartItemType }) {
      context.commit("UPDATE_ITEM", data);
    },
    addCartClient(context, client: any) {
      context.commit("SET_CLIENT", client);
    },
    deleteCart(context) {
      context.commit("DELETE_CART");
    },
    async fetchDiscounts(context, businessId) {
      return await api
        .get(
          `/v1/discount?businessId=${businessId}&limit=10000&platform=vendor-ui`
        )
        .then((response) => {
          context.commit("SET_DISCOUNTS", response.data.discountPage.docs);
          context.commit(
            "SET_APPLIED_DISCOUNTS",
            response.data.discountPage.docs
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    setAppliedDiscounts(context, discounts) {
      context.commit("SET_APPLIED_DISCOUNTS", discounts);
    },
  },
};

export default cart;
